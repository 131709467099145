import React from "react"

import { Modal } from "react-bootstrap"

import ScheduleForm from "../form/schedule-form"

const ScheduleModal = props => {
  return (
    <>
      <div>
        <Modal
          {...props}
          className="modal-wrapper"
          size="lg"
          // show={lgShow}
          // onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
          centered
        >
          <Modal.Header closeButton style={{ borderBottom: "none" }} />
          <Modal.Body>
            <ScheduleForm />
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default ScheduleModal
