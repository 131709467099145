import React from "react"
import { Link } from "gatsby"

import { FaMapMarkedAlt, FaFacebookF, FaPhoneAlt, FaLinkedinIn, FaTwitter, FaInstagram, FaEnvelope  } from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <div className="footer-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-7">
              <h3 className="title">Pacific Coast Land Solutions</h3>
              <p className="lead mt-3">
                Benefit from Pacific Coast’s competitive prices, agile and
                high-quality services, profitable partnerships, and
                state-of-the-art AI data extraction. Together, we can come up
                with strategies that will lead to the best results for your
                ventures. Get in touch with us and let us start working towards
                your success.
              </p>
            </div>
            <div className="col-lg-2 quick-links-box">
              <h3 className="title">quick links</h3>
              <div className="quicklinks-content d-flex flex-column mt-3">
                <Link to="/">Home</Link>
                <Link to="/#our-team">Meet the Team</Link>
                <Link to="/#about-section">About</Link>
                <Link to="/#services">Services</Link>
                <Link to="/blog">Blog</Link>
                <Link to="/#contact">Contact</Link>
              </div>
            </div>
            <div className="col-lg-3 footer-contact-box">
              <div className="footer-contact">
                <h3 className="title mb-3">contact us</h3>
                <div className="d-flex align-items-center">
                  <FaMapMarkedAlt
                    
                    className="mr-3 text-white"
                  />
                  <p className="text-white mb-0">
                    269 W. Bonita Ave,
                    <br />
                    Claremont, CA 91711
                  </p>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <FaEnvelope
                   
                    className="mr-3 text-white"
                  />
                  <a
                    href="mailto:info@pacificcoastls.com"
                    className="text-white"
                  >
                    info@pacificcoastls.com
                  </a>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <FaPhoneAlt
                 
                    className="mr-3 text-white"
                  />
                  <a href="tel:7202341155" className="text-white">
                    720.234.1155
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="sub-footer-wrapper">
        <div className="container-fluid">
          <div className="sub-footer-box">
            <p className="mb-0">
              Designed and Powered by{" "}
              {/* <Link to="https://www.webriq.com/">WebriQ</Link> */}
              <a
                href="https://www.webriq.com/"
                target="_blank"
                referrer="noopener noreferrer"
              >
                WebriQ
              </a>
            </p>
            <p className="mb-0">© {new Date().getFullYear()} Pacific Coast LAND SOLUTIONS. All Rights Reserved.</p>
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/pacific-coast-land-solutions/"
              >
                <FaLinkedinIn className="mr-3" />
              </a>
              {/* <Link to="/">
                <FontAwesomeIcon icon={faTwitter} className="mr-3" />
              </Link>
              <Link to="/">
                <FontAwesomeIcon icon={faInstagram} className="mr-3" />
              </Link>
              <Link to="/">
                <FontAwesomeIcon icon={faFacebookF} className="mr-3" />
              </Link> */}
            </div>
          </div>
        </div>{" "}
      </div>{" "}
    </>
  )
}

export default Footer
