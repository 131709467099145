import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import ScheduleModal from "../modal/schedule"
import logo from "../../images/logo.png"
import { Navbar, Container } from "reactstrap"
import SideBarMenu from "../sidebar/sidebar"
import CertificateStateSelection from "../certificate-state-selection"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FaPhoneAlt } from "react-icons/fa";

const Header = () => {
  const [scrollY, setScrollY] = useState(0)

  function logit() {
    setScrollY(window.pageYOffset)
  }
  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit)
    }
    watchScroll()
    // Remove listener (like componentWillUnmount)
    return () => {
      window.removeEventListener("scroll", logit)
    }
  }, [])

  const [scheduleShow, setScheduleShow] = useState(false)

  return (
    <>
      <div className="navbar-wrapper">
        <Navbar
          fixed="top"
          className={`py-2 navbar-wrapper ${
            // scrollY > 0 ? "navbar-scrolled" : "navbar-still"
            scrollY > 0 ? "navbar-scrolled" : "navbar-scrolled"
          }`}
        >
          <Container fluid>
            <div className="mr-auto">
              <Link to="/">
                <img
                  // width={scrollY > 0 ? "100%" : "100%"}
                  id="logo-size"
                  src={logo}
                  alt="Grayhawk"
                  className="logo sepia img-fluid"
                />
              </Link>
            </div>
            <div className="mr-auto d-none d-lg-block">
              <CertificateStateSelection />
            </div>
            <div className="text-right text-xl-left d-block d-xl-flex justify-content-center align-items-center button-wrapper-desktop">
              <div className="order-2 mb-2 mb-xl-0">
                <button
                  onClick={() => setScheduleShow(true)}
                  className="header-cta request-btn mr-3 text-white"
                >
                  SCHEDULE A MEETING
                </button>
                <ScheduleModal
                  show={scheduleShow}
                  onHide={() => setScheduleShow(false)}
                />
              </div>
              <div className="order-1">
                <FaPhoneAlt
                  
                  className="mr-3 text-dark"
                  style={{ fontSize: "1rem" }}
                />
                <a href="tel:7202341155" className="header-call mr-3 text-dark">
                  720.234.1155
                </a>
              </div>
            </div>
            <SideBarMenu />
          </Container>
          {/* <Container className="justify-content-end my-3">
            <FontAwesomeIcon icon={faClock} className="mr-3 text-white" />
            <p className="m-0 text-white">mon- sat: 08:00- 18:00</p>
          </Container> */}
        </Navbar>
      </div>
    </>
  )
}

export default Header
