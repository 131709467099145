import React from "react"
import { Dropdown } from "react-bootstrap"

const StateDropdown = () => {
	return (
		<div className="cert-state-dropdown-selection">
			<p className="uppermost-text mb-1 mr-4">
				USE OUR EXPERTISE TO YOUR ADVANTAGE
			</p>
			<div className="cdbe-state-wrapper">
				<h5 className="cdbe-state mb-1">
					CERTIFIED DISADVANTAGED BUSINESS ENTITY
				</h5>
				<Dropdown className="mt-2">
					<Dropdown.Toggle variant="primary" id="dropdown-basic">
						IN THE STATE OF
					</Dropdown.Toggle>

					<Dropdown.Menu>
						<Dropdown.Item
							target="_blank"
							href={`../../files/DBE_Cert_Letter_for_the_State_of_Hawaii.pdf`}
						>
							hawaii
						</Dropdown.Item>
						<Dropdown.Item
							target="_blank"
							href={`../../files/DBE_Approval_from_the_State_of_California.pdf`}
						>
							california
						</Dropdown.Item>
						<Dropdown.Item
							target="_blank"
							href={`../../files/DBE_Certification_Letter_from_Oregon_DOT.pdf`}
						>
							oregon
						</Dropdown.Item>
						<Dropdown.Item
							target="_blank"
							href={`../../files/DBE_Certification_Letter_from_AZDOT.pdf`}
						>
							arizona
						</Dropdown.Item>
						<Dropdown.Item
							target="_blank"
							href={`../../files/CDOT_DBE_Approval_for_Pacific_Coast_Land_Solutions_Letter.pdf`}
						>
							colorado
						</Dropdown.Item>
						<Dropdown.Item
							target="_blank"
							href={`../../files/DBE_Certification_Letter_from_Oklahoma_DOT.pdf`}
						>
							oklahoma
						</Dropdown.Item>
						<Dropdown.Item
							target="_blank"
							href={`../../files/01_DBE_ Approved_App_PacCoasLandSol.pdf`}
						>
							texas
						</Dropdown.Item>
						<Dropdown.Item
							target="_blank"
							href={`../../files/Certificate_for_Supplier_Clearinghouse_for_the_State_of_California-Minority_Business_Enterprise.pdf`}
						>
							cpuc
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</div>
		</div>
	)
}

export default StateDropdown
