import React from "react"

import WebriQForm from "@webriq/gatsby-webriq-form"

const ScheduleForm = () => {
  return (
    <>
      <div className="schedule-form-wrapper">
        <WebriQForm
          method="POST"
          data-form-id="5a653a38-e26c-4b20-8ac3-97edba63c771"
          name="Schedule Form"
          className="form-newsletter"
          data-thankyou-url="/thank-you"
        >
          <div className="form-row">
            <div className="col-12 mb-3">
              <div className="form-group">
                <input
                  className="form-control full-name"
                  type="text"
                  name="Full Name"
                  id="full_name"
                  placeholder="YOUR NAME"
                />
                <input
                  className="form-control email"
                  type="email"
                  name="Email"
                  id="email_add"
                  placeholder="EMAIL"
                />
                {/* <input
                  className="date-input"
                  type="date"
                  id="birthday"
                  name="birthday"
                /> */}
                <input
                  className="date-input"
                  type="datetime-local"
                  id="meeting-time"
                  name="Meeting Schedule"
                  // value="2018-06-12T19:30"
                  // min="2018-06-07T00:00"
                  // max="2018-06-14T00:00"
                />
              </div>
            </div>

            <div className="col-12">
              <div className="form-group">
                <div className="webriq-recaptcha" />
              </div>
            </div>

            <div className="col-12">
              <div className="form-group">
                <button className="btn hvr-shadow submit-btn" type="submit">
                  SEND
                </button>
              </div>
            </div>
          </div>
        </WebriQForm>
      </div>{" "}
    </>
  )
}

export default ScheduleForm
