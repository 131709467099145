import React, { useState } from "react"
import { Link } from "gatsby"
// import { UncontrolledCollapse } from "reactstrap"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import ScheduleModal from "../modal/schedule"

import { FaPhoneAlt, FaTimes  } from "react-icons/fa";
import logo from "../../images/logo.png"

const Menu = ({ toggle }) => {
  const menus = [
    {
      title: "home",
      to: "/",
      subMenus: [],
    },
    {
      title: "meet the team",
      to: "/#our-team",
      subMenus: [],
    },
    {
      title: "about",
      to: "/#about-section",
      subMenus: [],
    },
    {
      title: "services",
      to: "/#services",
      subMenus: [],
    },
    // {
    //   title: "carriers",
    //   to: "/#",
    //   subMenus: [],
    // },
    {
      title: "blog",
      to: "/blog",
      subMenus: [],
    },
    {
      title: "contact",
      to: "/#contact",
      subMenus: [],
    },
    {
      title: "join our team",
      to: "/EmploymentApplication",
      subMenus: [],
    },
  ]

  const SubMenu = ({ title, subMenus }) => {
    return (
      <div>
        <div
          id={title}
          style={{
            padding: "15px 8px 15px 16px",
            borderBottom: "solid 1px #3C3735",
            color: "#818181",
            cursor: "pointer",
          }}
          className="text-uppercase"
        >
          {title}
        </div>
        {/* <UncontrolledCollapse toggler={title}>
          <div className="d-flex flex-column">
            {subMenus.map((item, index) => (
              <MenuLink key={index} to={item.to}>
                {item.title}
              </MenuLink>
            ))}
          </div>
        </UncontrolledCollapse> */}
      </div>
    )
  }

  const MenuLink = ({ toggle, to, children }) => {
    return (
      <Link onClick={toggle} className="text-uppercase menu-links" to={to}>
        {children}
      </Link>
    )
  }

  const [scheduleShow, setScheduleShow] = useState(false)

  return (
    <>
      <div className="d-flex flex-column">
        {/* <button onClick={() => toggle()} className="close-btn">
        x
      </button> */}
        <div className="close-box">
          <FaTimes
            className="close-btn"
         
            onClick={toggle}
          />
        </div>
        <Link to="/" className="text-logo">
          <img src={logo} alt="Grayhawk" style={{ paddingBottom: "2rem" }} />
        </Link>
        {menus.map((item, index) => {
          if (item.subMenus.length > 0)
            return (
              <SubMenu
                toggle={toggle}
                title={item.title}
                subMenus={item.subMenus}
              />
            )
          return (
            <MenuLink toggle={toggle} key={index} to={item.to}>
              {item.title}
            </MenuLink>
          )
        })}
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center button-wrapper-mobile">
        <div className="mb-3">
          <button
            onClick={() => setScheduleShow(true)}
            type="button"
            className="request-btn text-white"
          >
            SCHEDULE A MEETING
          </button>
          <ScheduleModal
            show={scheduleShow}
            onHide={() => setScheduleShow(false)}
          />
        </div>
        <div>
          <FaPhoneAlt  className="mr-3 text-white" />
          <a href="tel:720.234.1155" className="mr-3 text-white">
            720.234.1155
          </a>
        </div>
      </div>
    </>
  )
}

export default Menu
